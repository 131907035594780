import {
  painReliefCitations,
  pregnancyInfancyCitations,
  sportInjuryCitations,
} from '../../static/citations';

import { Fade } from 'react-awesome-reveal';
import { Helmet } from 'react-helmet-async';
import { PageTitle } from '../../components';
import logoOg from '../../assets/images/logoog.jpg'

type citationProp = {
  link?: string;
  infoText?: string;
  ariaLabel: string;
  citationNumber: number;
};

const Citations = () => {
  return (
    <div className="py-12 bg-white">


      <Helmet>
        <title>Kenworthy Chiropractic | Citations</title>
        <meta property='og:description' content='Kenworthy Chiropractic – Citations and references were have quoted or using throughout the website' />
        <meta property="og:title" content={`Kenworthy Chiropractic | Citations`} />
        <meta property="og:type" content="website" />"
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={logoOg} />
        <meta property="og:image:secure_url" content={logoOg} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
      </Helmet>


      <div className="max-w-5xl mx-auto lxs:px-1 sm:px-5 ">
        <PageTitle title="Citations & Referencing" />

        <div className="flex flex-col items-start justify-center w-full max-w-5xl mx-auto mt-8">
          <div className="w-full h-auto mt-8">
            <h4 className="pb-6 text-xl font-semibold">Pain Relief</h4>
            <Fade direction='up' cascade damping={0.1} triggerOnce>
              {painReliefCitations.map(
                (
                  { link, citationNumber, infoText, ariaLabel }: citationProp,
                  index: number
                ) => (
                  // <Fade key={index} distance={`${index * 10 + 10}px`} bottom>
                  <div
                    key={index}
                    className="flex flex-row items-start justify-start w-auto h-auto my-6 overflow-x-scroll sm:overflow-hidden"
                    id={`A${citationNumber}`}
                  >
                    <span className="flex items-center font-semibold select-none">
                      A{citationNumber}.
                    </span>
                    {link && infoText ? (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {infoText}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    ) : (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    )}
                  </div>
                )
              )}
            </Fade>
          </div>

          <div className="w-full h-auto mt-8">
            <h4 className="pb-6 text-xl font-semibold">Pregnancy & Infancy</h4>

            <Fade direction='up' cascade damping={0.1} triggerOnce>
              {pregnancyInfancyCitations.map(
                (
                  { link, citationNumber, infoText, ariaLabel }: citationProp,
                  index: number
                ) => (
                  <div
                    key={index}
                    className="flex flex-row items-start justify-start w-auto h-auto my-6 overflow-x-scroll sm:overflow-hidden"
                    id={`B${citationNumber}`}
                  >
                    <span className="flex items-center font-semibold select-none">
                      B{citationNumber}.
                    </span>
                    {link && infoText ? (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {infoText}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    ) : (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    )}
                  </div>
                )
              )}
            </Fade>
          </div>

          <div className="w-full h-auto mt-8">
            <h4 className="pb-6 text-xl font-semibold">Sports & Injury</h4>

            <Fade direction='up' cascade damping={0.1} triggerOnce>
              {sportInjuryCitations.map(
                (
                  { link, citationNumber, infoText, ariaLabel }: citationProp,
                  index: number
                ) => (
                  <div
                    key={index}
                    className="flex flex-row items-start justify-start w-auto h-auto my-6 overflow-x-scroll sm:overflow-hidden"
                    id={`C${citationNumber}`}
                  >
                    <span className="flex items-center font-semibold select-none">
                      C{citationNumber}.
                    </span>
                    {link && infoText ? (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {infoText}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    ) : (
                      <p className="flex ml-1" id={`C${citationNumber}`}>
                        {link ? (
                          <a
                            href={link}
                            aria-label={ariaLabel}
                            className="text-[#846bb9] hover:text-[#6bb2b9]"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {link}
                          </a>
                        ) : (
                          infoText
                        )}
                      </p>
                    )}
                  </div>
                )
              )}
            </Fade>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Citations;
