import { FaAngleDown, FaFacebookF } from "react-icons/fa";
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { useEffect, useRef, useState } from "react";

import { BsTwitterX } from "react-icons/bs";
import { Fade } from "react-awesome-reveal";
import { Helmet } from 'react-helmet-async';
import Loader from '../../components/Loader/Loader';
import { RiWhatsappFill } from "react-icons/ri";
import clientConfig from "../../client-config";
import { formatDate } from '../../utils/functions';
import logo from '../../assets/images/logo.webp';
import logoOg from '../../assets/images/logoog.jpg'
import { useLocation } from "react-router";

const PostDetail = () => {

	// Get the location object from the useLocation hook
	// Extract the pathname from the location object
	// Split the pathname by '/' and get the last part
	const location = useLocation();
	const pathname = location.pathname;
	const postSlug = pathname.split('/').pop();

	// Create a reference for the <p> element
	const contentPRef = useRef<HTMLParagraphElement>(null);

	// State to track if height is greater than 500px
	const [isOverflow, setIsOverflow] = useState<boolean>(false);
	const [post, setPost] = useState<Post>()
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false)

	// USE EFFECTS
	useEffect(() => {
		const element = contentPRef.current;

		// Check if the element's scrollHeight is greater than 500px
		if (element && element.scrollHeight > 499) {
			setIsOverflow(true);
		}

		const getPost = async () => {
			setLoading(true);
			try {
				const response = await fetch(`${clientConfig.siteUrl}/wp-json/wp/v2/posts?slug=${postSlug}&_embed`);
				const data = await response.json();

				if (response.status === 200) {
					setPost(data[0])
					setLoading(false)
				}
			} catch (error) {
				setLoading(false);
				setError(true);
				console.log(error);;
			}
		}
		getPost();
	}, [postSlug]); // Empty dependency array ensures this effect runs only once after the component moun


	if (loading) return (
		<Loader logo={logo} textLineOne="Loading Post!" />
	);

	if (error) return (
		<Loader logo={logo} textLineOne="Error Loading Post!" textLineTwo="Please try refreshing the page." />
	);


	if (post) return (
		<>
			<Helmet>
				<title>Kenworthy Chiropractic | {post && post.title.rendered.toUpperCase()}</title>
				<meta property='og:description' content={`Kenworthy Chiropractic – ${post && post.title.rendered} by ${post && post._embedded.author[0].name}`} />
				<meta property="og:title" content={`Kenworthy Chiropractic | ${post && post.title.rendered.toUpperCase()}`} />
				<meta property="og:image" content={post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : logoOg} />
				<meta property="og:image:secure_url" content={post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : logoOg} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="article" />
			</Helmet>

			<div className='w-full h-full bg-white'>


				<div className='h-full max-w-5xl mx-auto bg-white lxs:px-1 sm:px-5'>
					<Fade triggerOnce direction='up' cascade damping={0.1}>
						<div className='grid w-full h-full grid-cols-1 gap-2 py-24 sm:grid-cols-2'>

							{/* Image Container */}
							<div className='flex flex-col items-start justify-start w-full h-auto sm:h-full'>

								{/* Image */}
								<img
									src={post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url}
									alt={post.title.rendered}
									className={`object-contain rounded-md select-none w-full h-full max-h-[400px] sm:w-fit sm:h-fit sm:max-h-full ${!post._embedded['wp:featuredmedia'] && 'hidden'}`}
									loading="lazy"
								/>
							</div>


							{/* Info Container */}
							<div className='flex flex-col items-center justify-start w-full h-full gap-2 px-4 mt-5 sm:px-0 sm:items-start sm:mt-0 sm:pl-5 md:pl-10'>
								{/* Title */}
								<p className='mb-2 text-4xl font-bold' dangerouslySetInnerHTML={{ __html: post.title.rendered }}></p>

								{/* Share Buttons */}
								<div className="flex flex-row items-center justify-center w-full gap-2 sm:justify-start">
									<p className="pr-2 font-semibold text-md">Share:</p>
									{/* Whatsapp */}
									<WhatsappShareButton
										url={window.location.href}
										title={`Take a look at this post by ${post._embedded.author[0].name} at Kenworthy Chiropractic!`}
										separator=":: "
									>
										<RiWhatsappFill className="h-[25px] w-[25px] text-primary hover:text-secondary transition-all ease duration-200" />
									</WhatsappShareButton>

									{/* Twitter */}
									<TwitterShareButton
										url={window.location.href}
										title={`Take a look at this post by ${post._embedded.author[0].name} at Kenworthy Chiropractic!`}
									>
										<BsTwitterX className="h-[20px] w-[20px] text-primary hover:text-secondary transition-all ease duration-200" />
									</TwitterShareButton>

									{/* Facebook */}
									<FacebookShareButton
										url={window.location.href}
									>
										<FaFacebookF className="h-[20px] w-[20px] text-primary hover:text-secondary transition-all ease duration-200" />
									</FacebookShareButton>
								</div>

								{/* Content */}
								<p ref={contentPRef} className={`text-lg font-normal text-gray-600 max-h-[500px] overflow-scroll`} dangerouslySetInnerHTML={{ __html: post.content.rendered }}></p>

								{/* Arrow when content is over 499px height*/}
								<div className={`${isOverflow ? 'w-full flex' : 'hidden '}`}>
									<FaAngleDown className="text-[30px] animate-bounce w-full text-center" />
								</div>

								{/* Author / Date */}
								<p className='text-sm italic text-gray-500 '>
									<span>
										by {post._embedded.author[0].name}
									</span>
									&nbsp;on&nbsp;
									<span>
										{formatDate(post.date)}
									</span>
								</p>
							</div>

						</div>
					</Fade>
				</div>
			</div>
		</>
	);
};

export default PostDetail