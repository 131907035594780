import {
  CircularNavigationContainer,
  DynamicDays,
  Reviews,
  WelcomeArea,
} from '../../components';

import { Helmet } from 'react-helmet-async';
import clientConfig from '../../client-config';
import logoOg from '../../assets/images/logoog.jpg'
import { reviews } from '../../static/reviews';
import useFetchSpecialDay from '../../hooks/useFetchSpecialDay';

const Home = () => {
  // Get the wordpress site url
  const wordPressSiteURL = clientConfig.siteUrl;
  // Hook ot get the special day events
  const { events } = useFetchSpecialDay(wordPressSiteURL);


  return (
    <>

      <Helmet>
        <title>Kenworthy Chiropractic | Chiropractor Preston</title>
        <meta property='og:description' content='Making Life Better. Preston based chiropractor specialising in McTimoney chiropractic treatment.' />
        <meta property="og:title" content={`Kenworthy Chiropractic`} />
        <meta property="og:type" content="website" />"
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={logoOg} />
        <meta property="og:image:secure_url" content={logoOg} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
      </Helmet>

      <div className="flex flex-col w-full justify-start items-center bg-[#ffffff]">

        <DynamicDays events={events} />

        <CircularNavigationContainer />

        <div className="max-w-5xl">
          {/* <Fade distance="30px" bottom> */}
          <WelcomeArea />
          {/* </Fade> */}
        </div>
        <div className="w-full flex flex-col justify-center items-center bg-[#2ab8b4]">
          <Reviews reviewObj={reviews} />
        </div>
      </div>
    </>
  );
};

export default Home;
