import { FaqDropDown, PageTitle } from '../../components';

import { Fade } from "react-awesome-reveal";
import { Helmet } from 'react-helmet-async';
import SpineAnimationMp4 from '../../assets/animations/animation-spine.mp4';
import SpineAnimationWebM from '../../assets/animations/animation-spine.webm';
import { faqAnswers } from '../../static/faq';
import logoOg from '../../assets/images/logoog.jpg'

const Faq = () => {
  return (
    <div className="py-12 bg-white">


      <Helmet>
        <title>Kenworthy Chiropractic | FAQ</title>
        <meta property='og:description' content='Kenworthy Chiropractic – Questions you might have regarding the clinic, our polices, treatments and more' />
        <meta property="og:title" content={`Kenworthy Chiropractic | FAQ`} />
        <meta property="og:type" content="website" />"
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={logoOg} />
        <meta property="og:image:secure_url" content={logoOg} />
        <meta property="og:image:width" content="1920" />
        <meta property="og:image:height" content="1080" />
      </Helmet>

      <div className="max-w-5xl px-5 mx-auto">
        <PageTitle title="FAQ" />
        <Fade triggerOnce direction='right' cascade damping={0.1} duration={4000}>
          <div className="w-full h-[50px] relative sm:hidden md:block">
            <video
              className="absolute h-[250px] w-[250px] lg:h-[320px] lg:w-[320px] lg:top-[-190px] top-[-138px] right-0"
              loop
              autoPlay
              muted
              playsInline
            >
              <source src={SpineAnimationWebM} type="video/webm" />
              <source src={SpineAnimationMp4} type="video/mp4" />
            </video>
          </div>
        </Fade>

        <div className="relative px-5">
          <Fade triggerOnce direction='left' cascade damping={0.1}>
            {faqAnswers.map((answer, index) => (
              <FaqDropDown
                key={index}
                title={answer.title}
                description={answer.description ? answer.description : ''}
                link={answer.link ? answer.link : false}
                linkTo={answer.linkTo}
                image={answer.image}
              />
            ))}
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Faq;
