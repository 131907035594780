import {
  Bookings,
  Citations,
  Faq,
  FeesAndOpening,
  Home,
  MeetTheTeam,
  News,
  PainRelief,
  PostDetail,
  PregnancyAndInfancy,
  SportAndInjury,
  VideoExercises,
} from './Routes/index';
import { Footer, Header, SideNavBar } from './components';
import { Navigate, Route, Routes } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';

const App = () => {
  const [sideNavStatus, setSideNavStatus] = useState(false);

  const handelSideMenuToggle = () => {
    sideNavStatus === true ? setSideNavStatus(false) : setSideNavStatus(true);
  };

  return (
    <HelmetProvider>
      {/* Sidebar */}
      <SideNavBar
        navStatus={sideNavStatus}
        toggleNavStatus={handelSideMenuToggle}
      />
      <div
        className={`ease-in-out transition-all duration-500 relative ${sideNavStatus && 'translate-x-[-20rem]'}`}
      >
        <div className={`relative z-[2]`}>
          <Header
            navStatus={sideNavStatus}
            toggleNavStatus={handelSideMenuToggle}
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/pain-relief" element={<PainRelief />} />
            <Route path="/meet-the-team" element={<MeetTheTeam />} />
            <Route
              path="/pregnancy-infancy"
              element={<PregnancyAndInfancy />}
            />
            <Route path="/news" element={<News />} />

            <Route path="/sport-injury" element={<SportAndInjury />} />
            <Route path="/fees" element={<FeesAndOpening />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/video-exercises" element={<VideoExercises />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/citations" element={<Citations />} />
            {/* <Route path="/other-services" element={<OtherServices />} /> */}

            <Route path="/news" element={<News />} />
            <Route path="/news/post/:postTitle" element={<PostDetail />} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer />
      </div >
    </HelmetProvider>
  );
};

export default App;
