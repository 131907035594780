import { useEffect, useState } from 'react';

import DynamicDay from '../DynamicDay/DynamicDay';
import { SpecialDaysProps } from '../../types/types';

type Props = {
  events: SpecialDaysProps[];
}


const DynamicDays = ({ events }: Props) => {
  // Use States
  const [specialDay, setSpecialDay] = useState<SpecialDaysProps>();

  useEffect(() => {

    // Gets todays date
    const today = new Date();

    // Maps over all events
    events.map((event: SpecialDaysProps) => {

      // If event marked as not active, it is skipped
      if (!event.active) {
        return null;
      };

      // Maps through the event's date array. if a date matches with the current date, the event is set in the state
      event.days.map((day) => {
        if (`${day.value}-${event.month.value}` === `${today.getDate()}-` + (today.getMonth() + 1)) {
          setSpecialDay(event);
          return null;
        } else {
          return null;
        }
      });
      return null;
    })
  }, [events]);

  if (specialDay === undefined) return null;

  return (
    <div className="w-full h-auto">
      <DynamicDay day={specialDay} />
    </div>
  );
};

export default DynamicDays;
